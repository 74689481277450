import React, { useContext, useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom';

//import PropTypes, { object } from 'prop-types'

//import { EventBus } from 'jslib/eventBus'
import { getFromApi } from '../lib/api';

//import useTranslations from "../lib/useTranslations";
import { CartContext } from '../lib/CartContext';

import { StandardStepper, TakeawayStepper, PaymentStepper, ChangedStepper, CostsStepper, RejectedStepper } from './order_status'

export default function OrderProgress(props) {
  const { menu } = props
  const [order, setOrder] = useState(props.order)
  const [timer, setTimer] = useState(null)
  /* eslint-disable no-unused-vars */
  const [message, setMessage] = useState(null)

  const { OrderStatus, clearOrder, MessageSubject } = useContext(CartContext);

  const timerRef = React.useRef(null)
  timerRef.current = timer

  const orderRef = React.useRef(null)
  orderRef.current = order


  const history = useHistory();

  /* eslint-disable react-hooks/exhaustive-deps */
  var dataListener

  function listenForData(socket) {
    //  get acknowledgements - order received, order status etc.

    socket.addEventListener("message", dataListener = (e) => {

      let data = JSON.parse(e.data);

      if (data.Payload) {
        data = JSON.parse(data.Payload)
      }

      if (data.event === "message") {

        //console.log('message recvd', data)

        //let msg = JSON.parse(data.payload)

        //console.log('payload recvd', msg)

        handleMessage(data)
      }

    })
  }

  function onSocketOpen(socket, username) {
    // subscribe to username topic
    var msg =
    {
      "event": "subscribe",
      "channel": username,
      "payload": "subscribe"
    }
    socket.send(JSON.stringify(msg))
  }

  function connectToChatServer(username, callback) {

    let url = "wss://" + window.location.host + `/chat/orders/${username}`
    if (window.location.host.indexOf("localhost") > -1) {
      url = `ws://localhost:3008/chat/orders/${username}`
    }

    //console.log("connecting to chat server", url)

    window.orderSocket = new WebSocket(url);

    listenForData(window.orderSocket)

    let socket = window.orderSocket

    socket.onopen = function (e) {
      //console.log("[open] Connection established as", username);
      //console.log("Sending to server", username);
      onSocketOpen(socket, username)

      callback(socket)
    };

    /* socket.onmessage = function (event) {
      //console.log(`[message] Data received from server: ${event.data}`);
    }; */

    socket.onclose = function (event) {
      if (event.wasClean) {
        //console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
      } else {
        // e.g. server process killed or network down
        // event.code is usually 1006 in this case
        //alert('Se perdió la conexión con el servidor, vuelva a cargar la ventana del navegador');

        //console.log(`[close] Connection closed code=${event.code} reason=${event.reason}`);
        // don't try to reconnect, the next submission will connect
        if (window.orderSocket !== undefined) {
          try {
            window.orderSocket.close()
          } catch { }
        }
        window.orderSocket = undefined

      }
    };

    socket.onerror = function (error) {
      //alert(`[error] ${error.message} - vuelva a cargar la ventana del navegador`);
      // close the socket
      if (window.orderSocket !== undefined) {
        try {
          window.orderSocket.close()
        } catch { }
      }
      window.orderSocket = undefined
      //console.log(`[ERROR] Connection closed reason=${error.message}`);
    };

    // establish an interval timer to send a ping
    let timer = setInterval(() => {
      var msg = {
        "event": "ping",
        "channel": "status",
        "payload": "ping"
      };
      //console.log("sending ping", msg)
      socket.send(JSON.stringify(msg))

      // check for updates from the API server
      _checkOrderUpdates()
    }, 15000)

    setTimer(timer)
  }

  function _checkOrderUpdates() {
    //console.log('_checkOrderUpdates', order)

    getFromApi("/api/v1/order/" + order.id, {}, (response) => {
      let order = orderRef.current

      //console.log('_checkOrderUpdates order', data.result, order)

      if (response.result.status !== order.status) {
        // the order has changed
        //console.log("order changed", data.result.status, order.status)

        if (response.result.status === OrderStatus.DeliveryCost) {
          _playAudio('DeliveryCost')
        } else if (response.result.status === OrderStatus.ConfirmCosts) {
          _playAudio('ExtraCosts')
        }

        response.result.menuId = menu.id  // ensure the order has the menu ID so we can detect when the menu changes
        // save the changed order to local storage
        //localStorage.setItem('order', JSON.stringify(response.result))
        setOrder(response.result)
      }
    },
      (error) => {

      })

  }

  function _establishConnection(guestId, callback) {
    if (window.orderSocket !== undefined) {
      callback(window.orderSocket)
    } else {
      //let guestId = localStorage.getItem('guestId')
      //console.log("establishing connection", guestId)
      connectToChatServer(guestId, callback)
    }
  }

  useEffect(() => {

    if (props.order) {
      setOrder(props.order)

      /* eslint-disable react-hooks/exhaustive-deps */
      _establishConnection(props.order.uuid, socket => {
        //console.log("connection established")
      })
    }


    return () => {
      if (timerRef.current !== null) {
        console.log("clear interval")
        clearInterval(timerRef.current)
        setTimer(null)
      } else {
        console.log("no interval to clear")
      }
      if (window.orderSocket !== undefined) {
        window.orderSocket.removeEventListener("message", dataListener)
        window.orderSocket.close()
        window.orderSocket = undefined
      }
    }
  }, [])


  function _playAudio(eventType) {

    let url = null

    //console.log("play audio", eventType)

    if (eventType === "DeliveryCost" || eventType === "ExtraCosts") {
      url = "https://www.zono.cc/assets/audio/menuatt.mpeg"
    } else if (eventType === "orderWaitingAudio") {
      url = "https://www.zono.cc/assets/audio/orderwaiting.mp3"
    } else {
      return
    }

    //console.log("play audio from url", url)

    // temp solution play the default url
    var player = document.getElementById("orders_audio_player")
    if (player && player.canPlayType("audio/mpeg")) {
      player.setAttribute("src", url)
      player.play()
    } else {
      console.log("no audio player")
      return
    }

    /* 
        if (0 > 1) {
          // get the configured URL for the eventType (orderReceived, orderWaiting, orderConfirmed, 
          // prepareOrder, orderReady)
          if (this.config == undefined) {
            console.log("no config object")
            return
          }
    
          let url = this.config.stringValue(eventType)
          let isEnabled = this.config.boolValue("playAudio")
    
          if (url && isEnabled) {
            // play the audio
            var player = document.getElementById("orders_audio_player")
            if (player && player.canPlayType("audio/mpeg")) {
              player.setAttribute("src", url)
              player.play()
            }
          }
        } */
  }


  function _orderFromPayload(payload) {
    if (typeof payload.content === 'string') {
      return JSON.parse(payload.content)
    }
    return payload.content
  }

  function handleMessage(msg) {

    //console.log('ProductOrder orderMessage recvd', msg)
    let payload = JSON.parse(msg.payload)
    let po

    if (msg.subject === 'ExtraCosts' ||
      msg.subject === 'DeliveryCost'
    ) {
      // noop - delivery cost messages have no order in the payload
    } else {
      po = _orderFromPayload(payload)

      if (po && po.id === order.id) {
        order.menuId = menu.id  // ensure the order has the menu ID so we can detect when the menu changes

        setOrder(po)
      }
    }

    switch (msg.subject) {
      case MessageSubject.DeliveryCost:

        //console.log('MenuCart DeliveryCost')
        order.status = OrderStatus.DeliveryCost

        order.shippingcost = 0
        //setShippingCost(0)
        setMessage(payload.message)

        _playAudio('DeliveryCost')

        break;

      case MessageSubject.OrderReceived:
        //console.log('Order received');
        order.status = OrderStatus.Received
        setMessage(payload.message)

        break;
      case MessageSubject.OrderAccepted:
        //console.log('Order accepted');
        order.status = OrderStatus.Preparing
        setMessage(payload.message)

        break;
      case MessageSubject.OrderRejected:
        //console.log('Order rejected');
        order.status = OrderStatus.Rejected
        setMessage(payload.message)
        localStorage.removeItem('order')

        break;
      case MessageSubject.OrderChange:
        //console.log('Order change required');
        order.status = OrderStatus.Change
        setMessage(payload.message)
        //EventBus.emit('orderChangeRequired')
        break;
      case MessageSubject.OrderChanged:

        //console.log('Order changed');
        order.status = OrderStatus.Changed
        setMessage(payload.message)

        /* vm.setState({
          packingCost: order.packingcost,
          shippingCost: order.shippingcost,
          otherCost: order.otherCost,
          otherReason: order.otherReason,
          newTotalCost: order.totalcost,
          confirmChangeRequired: true,
          message: payload.message,
        }) */
        break;
      case MessageSubject.ExtraCosts:
        // decode the content and update the additional costs
        let newCosts

        if (typeof payload.content === 'string') {
          newCosts = JSON.parse(payload.content)
        } else {
          newCosts = payload.content
        }

        //console.log('ProductOrder additional costs', newCosts)

        order.shippingcost = newCosts.shippingcost
        order.totalcost = newCosts.newTotalCost
        order.status = payload.status
        setMessage(payload.message)

        _playAudio('ExtraCosts')

        break;
      case MessageSubject.ConfirmRequired:
        //console.log('Order changed');
        order.status = OrderStatus.ConfirmRequired
        setMessage(payload.message)
        break;
      case MessageSubject.OrderPayment:
        //console.log('Order payment required');
        order.status = OrderStatus.PaymentRequired
        setMessage(payload.message)
        break;
      case MessageSubject.OrderPaid:
        //console.log('Order payment received');
        order.status = OrderStatus.Paid
        setMessage(payload.message)

        break;
      case MessageSubject.OrderReady:
        //console.log('Order ready for dispatch');
        order.status = OrderStatus.Ready
        setMessage(payload.message)

        break;
      case MessageSubject.OrderDispatched:
        //console.log('Order dispatched');
        order.status = OrderStatus.Dispatched
        setMessage(payload.message)
        localStorage.removeItem('order')
        break;
      case MessageSubject.PaymentLink:
        //console.log('Order payment link');
        setMessage(payload.message)
        //setLink(payload.content)

        break;
      case MessageSubject.PaymentQRcode:
        setMessage(payload.message)
        //setLink(payload.content)
        break;
      default:
        // noop
        break;
    }
  }

  function _hasPaymentStatus() {

    let orderStatus = order.status

    if (orderStatus === OrderStatus.PaymentRequired ||
      orderStatus === OrderStatus.Paid
    ) {
      return true
    }
    return false
  }

  function _hasConfirmCosts() {
    let orderStatus = order.status

    if (orderStatus === OrderStatus.ConfirmCosts ||
      orderStatus === OrderStatus.DeliveryCost) {
      console.log("confirm order or delivery costs", order)
      return true
    }
    return false
  }

  function _hasConfirmStatus() {
    let orderStatus = order.status

    if (orderStatus === OrderStatus.ConfirmRequired ||
      orderStatus === OrderStatus.Change ||
      orderStatus === OrderStatus.Changed ||
      orderStatus === OrderStatus.Confirmed
    ) {
      return true
    }
    return false
  }

  function _confirmOrder() {
    // let order = localStorage.getItem('order')

    // order = JSON.parse(order)
    //EventBus.emit("reconfirmOrder", order)
  }

  function _clearOrder() {
    localStorage.removeItem('order')
    localStorage.removeItem('pendingOrder')
    localStorage.removeItem('deliverLocation')
    localStorage.removeItem('deliverType')
    //localStorage.removeItem('tableNum')
    localStorage.removeItem('paymentMethod')
    //EventBus.emit('clearOrder')

    //let mdata = localStorage.getItem('paramData')

    //window.location.href = "/"
    const location = {
      pathname: '/detail',
      state: { selectedMenu: menu }
    }

    history.replace(location)
  }

  //console.log("order", order)

  if (order.status === OrderStatus.Rejected) {
    // the order has been rejected, show the rejected stepper
    return (
      <div className="menu menu-cart">
        <div className="row cart-body">
          <div className="col-12">
            <RejectedStepper order={order} />
          </div>
        </div>

        <div className="cart_control-button-row">

          <div className="new-order-button" onClick={_clearOrder} >
            Nuevo Pedido
          </div>

        </div>

      </div>
    )
  } else if (_hasConfirmCosts()) {
    // if the order has extra costs show a stepper with a confirmation button
    return (
      <div className="menu menu-cart">

        <div className="row cart-body">
          <div className="col-12">
            <CostsStepper order={order}

              onConfirm={_confirmOrder}
            />
          </div>
        </div>
        <audio id="orders_audio_player" />
      </div>
    )
  } else if (_hasConfirmStatus()) {
    // if order has been changed show the changed stepper with a confirmation button
    return (
      <div className="menu menu-cart">
        <div className="row cart-body">
          <div className="col-12">
            <ChangedStepper order={order} onConfirm={() => {
              //EventBus.emit("reconfirmOrder", order)
            }} />
          </div>
        </div>
        <audio id="orders_audio_player" />
      </div>
    )
  }

  if (order.deliverType === 'table') {
    return (
      <div className="menu menu-cart">
        <div className="row cart-body">
          <div className="col-12">
            <PaymentStepper order={order} />
          </div>
        </div>
        {(order.status === OrderStatus.Dispatched || order.status === OrderStatus.Delivered || order.status === OrderStatus.Paid ) &&
          <div className="cart_control-button-row">
            <div className="new-order-button" onClick={_clearOrder} >
            ¿Deseas algo mas?
            </div>
          </div>
        }
      </div>
    )
  }
  
  if (_hasPaymentStatus()) {
    // if the order requires payment or has been paid show the payment status

    return (
      <div className="menu menu-cart">
        <div className="row cart-body">
          <div className="col-12">
            <PaymentStepper order={order} />
          </div>
        </div>
        {( order.status === OrderStatus.Paid) &&
          <div className="cart_control-button-row">
            <div className="new-order-button" onClick={_clearOrder} >
              Nuevo Pedido
            </div>
          </div>
        }
      </div>
    )
  }

  if (order.deliverType === 'takeaway') {
    return (
      <div className="menu menu-cart">

        <div className="row cart-body">
          <div className="col-12">
            <TakeawayStepper order={order} />
          </div>
        </div>
        {(order.status === OrderStatus.Dispatched || order.status === OrderStatus.Delivered) &&
          <div className="cart_control-button-row">
            <div className="new-order-button" onClick={_clearOrder} >
              Nuevo Pedido
            </div>
          </div>
        }

        <audio id="orders_audio_player" />

      </div>
    )
  }

  // default delivery order type
  // the order has been submitted show the order status
  return (
    <div className="menu menu-cart">
      <div className="row cart-body">
        <div className="col-12">
          <StandardStepper order={order} />
        </div>
      </div>
      {(order.status === OrderStatus.Dispatched || order.status === OrderStatus.Delivered ) &&
        <div className="cart_control-button-row">
          <div className="new-order-button" onClick={_clearOrder} >
            Nuevo Pedido
          </div>
        </div>
      }
      {/*  {message &&
        <div>
          {message}
        </div>
      } */}

      <audio id="orders_audio_player" />

    </div>
  )

}
