import jquery from "jquery";

import { signString } from "./md5";

export function generateNonce(length) {
  length = 32;

  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export function signMap(user, map) {
  let nonce = generateNonce(32);

  //console.log("signString with", `${user.email}-${user.id}-${user.token}-${nonce}`)

  map["user_id"] = user.id;

  if (user.email) {
    map["signaturev2"] = signString(
      `${user.email}-${user.id}-${user.token}-${nonce}`
    );
    map["nonce"] = nonce;
  }
}

export function signedUrl(path) {
  let nonce = generateNonce(32)
  // test user
  let text = 'test@example.com-19-a0eebc99-9c0b-4ef8-bb6d-6bb9bd380a11-' + nonce

  let sigv2 = signString(text)

  return `${path}/19/${nonce}/${sigv2}`
}

export function getApiUrl(path) {
  if (path.startsWith("http")) return path;
  
  // test for dev or production server
  //console.log("test for dev or production server", window.location.host, window.location.hostname)
  if ( window.location.host === "localhost:8080" || window.location.host === "localhost:3000" ) {
    // webpack-dev-server uses dev.zono.cc
    return `http://localhost:3008${path}`;
    //return `https://dev.zono.cc${path}`;
  }

  // default to the location.host
  return `https://${window.location.host}${path}`;
}

function submitToApi(method, path, user, map, onSuccess, onError) {
  const $ = jquery;

  // sign the map with the user api key (token)
  signMap(user, map);

  const url = getApiUrl(path);

  //console.log("submit to API", method, url, map)

  const options = method === 'GET' ? {
    url: url,
    method: method,
    xhrFields: { withCredentials: true },
  } : {
    url: url,
    method: method,
    dataType: 'json',
    data: JSON.stringify(map),  // TODO check this doesn't break anything
    contentType: "application/json; charset=UTF-8",
    xhrFields: { withCredentials: true },
  }

  // submit to the URI denoted by path
  $.ajax(options)
    .then((data) => {
      //console.log('success', data)
      if (onSuccess) onSuccess(data);
    })
    .catch((data) => {
      //console.log('error', data)
      if (onError) onError(data);
    });
}

export function getFromApi(path, user, onSuccess, onError) {
  submitToApi("GET", path, user, {}, onSuccess, onError);
}

export function postToApi(path, user, map, onSuccess, onError) {
  submitToApi("POST", path, user, map, onSuccess, onError);
}

export function putToApi(path, user, map, onSuccess, onError) {
  submitToApi("PUT", path, user, map, onSuccess, onError);
}

export function deleteToApi(path, user, map, onSuccess, onError) {
  submitToApi("DELETE", path, user, map, onSuccess, onError);
}

export function uploadPhoto(
  method,
  path,
  user,
  map,
  element,
  onProgress,
  onLoad,
  onSuccess,
  onError
) {
  var fileSelect = document.getElementById(element);
  var files = fileSelect.files;
  var formData = new FormData(); // https://developer.mozilla.org/en-US/docs/XMLHttpRequest/FormData
  const url = getApiUrl(path);
  /*
  FormData available on
  IE 10+
  Firefox 4.0+
  Chrome 7+
  Safari 5+
  Opera 12+
*/
  const $ = window.$;

  // create the asset in an unpublished state then get the locations

  /* eslint-disable eqeqeq */
  if (files.length == 0) {
    alert("Por favor seleccione una nueva foto");
    return;
  }

  var file = files[0];

  // Check the file type.
  if (!file.type.match("image.*")) {
    alert("La foto no es una imagen.");
    return;
  }

  if (file.size > 1 * 1024 * 1024) {
    alert(
      "Error : La foto es demasiado grande, cambie el tamaño a menos de 1 MB"
    );
    return;
  }

  // Add the file to the request.
  formData.append("photo", file, file.name);
  formData.append("user_id", user.id);

  // sign the map with the user api key (token)
  signMap(user, map);

  Object.keys(map).forEach((key) => {
    formData.append(key, map[key]);
  });

  // send PUT request to server
  $.ajax({
    type: method,
    url: url,
    dataType: "json",
    xhr: function () {
      var myXhr = $.ajaxSettings.xhr();
      if (myXhr.upload) {
        myXhr.upload.addEventListener(
          "progress",
          function (e) {
            // upload progress event
            /* eslint-disable no-unused-vars */
            var percent_complete = (e.loaded / e.total) * 100;

            // Percentage of upload completed
            // console.log(percent_complete)

            let event = e;
            var percent = 0;
            var position = event.loaded || event.position;
            var total = event.total;

            /* eslint-disable no-unused-vars */
            var progress_bar_id = "#progress-wrp";
            if (event.lengthComputable) {
              percent = Math.ceil((position / total) * 100);
            }

            if (onProgress) onProgress(percent);
          },
          false
        );
      }
      // AJAX request finished event
      myXhr.addEventListener("load", function (e) {
        // HTTP status message
        //console.log('update logo finished', e.target)

        // request.response will hold the response from the server
        //console.log(myXhr.response)
        let res = JSON.parse(myXhr.response);

        if (onLoad) onLoad(res.result);
      });
      return myXhr;
    },
    success: function (data) {
      // set new asset
      //console.log('update logo success', data)
      if (onSuccess) onSuccess(data.result);

      fileSelect.value = null;
    },
    error: function (error) {
      // handle error

      if (onError) onError(error);
    },
    async: true,
    data: formData,
    cache: false,
    contentType: false,
    processData: false,
    timeout: 60000,
  });
}

export function postWithImage(
  path,
  user,
  map,
  element,
  onProgress,
  onLoad,
  onSuccess,
  onError
) {
  uploadPhoto(
    "POST",
    path,
    user,
    map,
    element,
    onProgress,
    onLoad,
    onSuccess,
    onError
  );
}

export function putWithImage(
  path,
  user,
  map,
  element,
  onProgress,
  onLoad,
  onSuccess,
  onError
) {
  uploadPhoto(
    "PUT",
    path,
    user,
    map,
    element,
    onProgress,
    onLoad,
    onSuccess,
    onError
  );
}
