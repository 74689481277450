import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col, Container, Form, Button, Tab, Nav, Image } from 'react-bootstrap';
//import ItemsCarousel from './common/ItemsCarousel';
//import GalleryCarousel from './common/GalleryCarousel';
import CheckoutItem from './common/CheckoutItem';
//import BestSeller from './common/BestSeller';
import QuickBite from './common/QuickBite';
import StarRating from './common/StarRating';
import RatingBar from './common/RatingBar';
//import Review from './common/Review';
import Icofont from 'react-icofont';
//import bannerTest from '../img/fondo.png'

import { getFromApi } from '../lib/api';
import useTranslations from "../lib/useTranslations";
import { CartContext } from '../lib/CartContext';
import { BusinessContext } from '../lib/BusinessContext';
import CardItem from './common/CardItem';
// import LatLon from '../lib/latlon';

//import { ConfigContext } from "../lib/ConfigContext";


const MenuGridItem = (props) => {
  const { menu, business, onSelected } = props;

  useEffect(() => {

  }, [])

  //console.log("business item", business)
  return (
    <div className="item col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 col-xxl-1"
      onClick={onSelected}
      style={{ backgroundColor: "transparent", border: "none", cursor: 'pointer' }}
    >
      <CardItem
        classContainer={'home-product-card'}
        title={menu.Location.name}
        subTitle={[menu.Location.address, menu.Location.city].join(', ')}
        imageAlt='Product'
        image={business.photo}
        imageClass='img-fluid item-img img-card'
        linkUrl='detail'
        offerText=''
        //closesAt={closeIn}
        //time='20–25 min'
        //price='$250 FOR TWO'
        //showPromoted={true}
        promotedVariant='dark'
        favIcoIconColor='text-danger'
      // rating='3.1 (300+)'
      />
    </div>
  )

}

const Detail = (props) => {

  const { cartItems, addItemToCart, clearCart, setItemQuantity, removeItem, pendingOrder, totalItemsCost } = useContext(CartContext);

  const { cityMenus, checkMenuPeriods, locationConfigBool, subscriptionLevel } = useContext(BusinessContext);

  const pricingSection = useRef(null);
  //const { config, getConfig } = useContext(ConfigContext);
  const { tr /* , getTranslations, locale, translations, setLocale */ } = useTranslations();
  const [selectedMenu, setSelectedMenu] = useState(null)
  const [ordersAvailable, setOrdersAvailable] = useState(false)
  const [hasOrdering, setHasOrdering] = useState(true)
  const [optionsFirst, setOptionsFirst] = useState(false)
  const [openCart, setOpenCart] = useState(false)
  const [availableMenus, setAvailableMenus] = useState(null)
  const [currentCategory, setCurrentCategory] = useState(null)

  const location = useLocation()

  //console.log(location.state) 

  const checkmenu = (menu, hasCredit) => {
    let openPeriod = checkMenuPeriods(menu)

    setOrdersAvailable(hasCredit && openPeriod.isOpen)
  }

  const _showMenu = (business, hasCredit, menu) => {
    setSelectedMenu(menu)

    checkmenu(menu, hasCredit)

    setOptionsFirst(locationConfigBool(business.Configs, menu.Location.id, "optionsBeforeAddons", false))

    //console.log("load detail with", location.state.selectedMenu)

    // clear the cart if the items in the cart are from a different business
    /* eslint-disable react-hooks/exhaustive-deps */
    clearCart(menu.Location)
  }

  const _chooseMenu = (business, hasCredit, menus) => {

    if (menus.length === 1) {
      _showMenu(business, hasCredit, menus[0])
      return
    }

    // show an intermediary component to choose the location/menu
    setAvailableMenus(menus)
  }

  useEffect(() => {

    if (location.state) {

      if (location.state.Business) {
        // when there are multiple menus for the business we follow this path
        let business = location.state.Business
        let hasCredit = true // businessHasOrdering(business)
        //setHasOrdering(hasCredit)

        let menu
        getFromApi(`/api/v1/menu/business/${business.id}`, {}, (data) => {

          if (data.result.length === 0) {
            alert(tr("That business does not have an online menu or catalogue"))
          } else if (data.result.length === 1) {
            menu = data.result[0]
            _showMenu(business, hasCredit, menu)
          } else {

            // get the menus in the same city
            let menus = cityMenus(business, data.result)

            // find the default domicilio menu or a menu for the current city                  
            let deliveryMenus = menus.filter((m) => m.deliveries === true)

            if (deliveryMenus.length === 1) {
              menu = deliveryMenus[0]
              _showMenu(business, hasCredit, menu)
            } else if (deliveryMenus.length > 1) {
              _chooseMenu(business, hasCredit, deliveryMenus)
            } else {
              _chooseMenu(business, hasCredit, menus)
            }
          }

        }, (error) => {
          console.log('get business data failed', error)
        })
      } else if (location.state.selectedMenu) {
        let mData = location.state.selectedMenu
        setSelectedMenu(mData)

        //let business = mData.Business
        //business.subscriptionLevel = subscriptionLevel(business)
        //let hasCredit = true // businessHasOrdering(business)
        setHasOrdering(mData.orders)

        //console.log("hasOrdering", hasCredit, business)
        checkmenu(mData, true)
      }
    } else {
      window.location.href = "/"
    }

  }, [pendingOrder, cartItems]);


  function _getOffer() {
    if (selectedMenu && selectedMenu.Offers && selectedMenu.Offers.length > 0) {
      let offer = selectedMenu.Offers.find(offer => offer.isActive === true);

      return (
        <div className="bg-white rounded shadow-sm text-white mb-4 p-4 clearfix restaurant-detailed-earn-pts card-icon-overlap">
          <Image fluid className="float-left mr-3" src="/img/earn-score-icon.png" />
          <h6 className="pt-0 text-primary mb-1 font-weight-bold">{tr('OFFER')}</h6>
          <p className="mb-0">{offer.name}<span className="text-danger font-weight-bold">{offer.saving}</span></p>
          <div className="icon-overlap">
            <Icofont icon="sale-discount" />
          </div>
        </div>
      )
    }
  }

  //console.log("selectedMenu", selectedMenu)

  const getHeader = (menuData) => {

    let bizStyle = {}

    if (menuData && menuData.Business.banner && menuData.Business.banner.length > 1) {
      bizStyle = {
        backgroundImage: `url(${menuData.Business.banner})`
      }

      return (
        <section className="restaurant-detailed-banner" >
          <div className="text-center banner-styled row" style={{ maxHeight: "400px", minHeight: "260px", width: "100%", margin: 0 }}>
            <div className='col-desapear col-xl' style={{ backgroundColor: "whitesmoke", padding: 0, zIndex: 1 }}>

            </div>
            <img fluid className="col-12 col-xl-10" src={menuData.Business.banner} style={{ minHeight: "260px", maxHeight: "400px", maxWidth: "1200px", padding: 0 }} alt="" />
            <div className='col-desapear col-xl' style={{ backgroundColor: "whitesmoke", padding: 0, zIndex: 1 }}>

            </div>
          </div>
          {/* <div className="text-center banner-styled" style={bizStyle} >
            <Image fluid className="cover" src={'/images/transparent_logo.png'} />
          </div> */}
          <div className="restaurant-detailed-header" >
            <Container >
              <Row className="d-flex align-items-end">
                <Col md={8} xl={12}>
                  <div className="restaurant-detailed-header-left margin-xl" style={{ paddingTop: "30px" }}>
                    <Image fluid className="mr-3 float-left" alt="osahan" src={menuData ? menuData.Business.photo : ''} />
                    <h2 className="text-white">{menuData.Business.name}</h2>
                    <p className="text-white mb-1"><Icofont icon="location-pin" /> {menuData.Location.address}
                    </p>
                    <p className="text-white mb-0"><Icofont icon="food-cart" /> {menuData.Location.city}-{menuData.Location.province}
                    </p>
                  </div>
                </Col>
                {/* <Col md={4}>
              <div className="restaurant-detailed-header-right text-right">
                <Button variant='success' type="button"><Icofont icon="clock-time" /> 25–35 min
                </Button>
                <h6 className="text-white mb-0 restaurant-detailed-ratings">
                  <span className="generator-bg rounded text-white">
                    <Icofont icon="star" /> 3.1
                  </span> 23 {tr('Ratings')}
                  <Icofont icon="speech-comments" className="ml-3" /> 91 {tr('reviews')}
                </h6>
              </div>
            </Col> */}
              </Row>
            </Container>
          </div>

        </section>
      )
    }

    return (
      <section className="restaurant-detailed-banner" >
        <div className="text-center banner-styled" style={bizStyle} >
          <Image fluid className="cover" src={menuData ? menuData.Business.photo : ''} />
        </div>
        <div className="restaurant-detailed-header">
          <Container>
            <Row className="d-flex align-items-end">
              <Col md={8}>
                <div className="restaurant-detailed-header-left">
                  <Image fluid className="mr-3 float-left" alt="osahan" src={menuData ? menuData.Business.photo : ''} />
                  <h2 className="text-white">{menuData.Business.name}</h2>
                  <p className="text-white mb-1"><Icofont icon="location-pin" /> {menuData.Location.address}
                  </p>
                  <p className="text-white mb-0"><Icofont icon="food-cart" /> {menuData.Location.city}-{menuData.Location.province}
                  </p>
                </div>
              </Col>
              {/* <Col md={4}>
              <div className="restaurant-detailed-header-right text-right">
                <Button variant='success' type="button"><Icofont icon="clock-time" /> 25–35 min
                </Button>
                <h6 className="text-white mb-0 restaurant-detailed-ratings">
                  <span className="generator-bg rounded text-white">
                    <Icofont icon="star" /> 3.1
                  </span> 23 {tr('Ratings')}
                  <Icofont icon="speech-comments" className="ml-3" /> 91 {tr('reviews')}
                </h6>
              </div>
            </Col> */}
            </Row>
          </Container>
        </div>

      </section>
    )
  }

  function shareData(data) {

    if (navigator.share && typeof navigator.share === 'function') {
      navigator.share(data)
        .then(() => console.log('Successcul share'))
        .catch(() => console.log('Error sharing'))
    } else {
      // copy URL to clipboard
      navigator.clipboard.writeText(data.url)
      alert(tr("Menu link copied to clipboard"))
    }
  }

  const styleForCategory = (category) => {
    if (category === currentCategory) {
      return {
        borderBottom: '3px solid #F1AD3E'
      }
    }
    return {}
  }

  const _styleForCategoryScrollMenu = () => {
    if (currentCategory) {
      return {
        curser: 'pointer',
        position: 'sticky',
        top: '0%',
        zIndex: 10
      }
    }
    return {
      curser: 'pointer'
    }
  }

  if (!selectedMenu && availableMenus && availableMenus.length > 1) {
    // show a menu chooser

    return <>
      {getHeader(availableMenus[0])}

      <section className="offer-dedicated-body pt-2 pb-2 mt-4 mb-4">
        <Container>
          <Row>
            <Col md={12}>
              <div
                // breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid padding-home-product row centrar"
              // columnClassName="my-masonry-grid_column "

              >
                {availableMenus.map((menu, index) => (

                  <MenuGridItem menu={menu} business={menu.Business} key={index} onSelected={() => {
                    _showMenu(menu.Business, true, menu)
                  }} />

                ))}
              </div>

              <h4>Selecione tú sede más cercana</h4>
            </Col>


          </Row>
        </Container>
      </section>

    </>

  }

  if (!selectedMenu) {

    return <></>
  }

  let shareURL = {};
  let checkoutLocation = {}

  //console.log("render with", selectedMenu)
  shareURL = {
    title: selectedMenu.Business.Name,
    text: 'Visita este enlace para grandes ofertas',
    url: `https://xoonoo.com?bid=${selectedMenu.business_id}&lid=${selectedMenu.location_id}&mid=${selectedMenu.id} `
  };

  checkoutLocation = {
    pathname: '/checkout',
    state: { selectedMenu: selectedMenu, items: cartItems }
  }

  let categories = selectedMenu.Category.Categories.filter((c) => c.enabled === true).sort((a, b) => a.sequence - b.sequence)


  //console.log("detail with menu", selectedMenu)

  return (
    <>

      {getHeader(selectedMenu)}

      <Tab.Container defaultActiveKey="first">
        <section className="offer-dedicated-nav bg-white border-top-0 shadow-sm">
          <Container>
            <Row>
              <Col md={12}>
                {/* <span className="restaurant-detailed-action-btn float-right">
                  <Button variant='light' size='sm' className="border-light-btn mr-1" type="button"><Icofont icon="heart" className='text-danger' /> {tr('Mark as Favourite')}</Button>
                  <Button variant='light' size='sm' className="border-light-btn mr-1" type="button"><Icofont icon="cauli-flower" className='text-success' />  Pure Veg</Button>
                  <Button variant='outline-danger' size='sm' type="button"><Icofont icon="sale-discount" />  {tr('OFFERS')}</Button>
                </span> */}
                <Nav id="pills-tab">

                  {/* 
                    <Nav.Item>
                    <Nav.Link eventKey="first">{tr('Order Online')}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
										<Nav.Link eventKey="second">{tr('Gallery')}</Nav.Link>
									</Nav.Item> 
                  
                    <Nav.Item>
                    <Nav.Link eventKey="third">{tr('Restaurant Info')}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="fourth">{tr('Book A Table')}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="fifth">{tr('Ratings & Reviews')}</Nav.Link>
                  </Nav.Item>
                  */}

                </Nav>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="offer-dedicated-body pt-2 pb-2 mt-4 mb-4">
          <Container>
            <Row>
              <Col md={8}>
                <div className="offer-dedicated-body-left">
                  <Tab.Content className='h-100'>
                    <Tab.Pane eventKey="first">
                      {/* <h5 className="mb-4">{tr('Categories')}</h5>
                      <Form className="explore-outlets-search mb-4">
                        <InputGroup>
                          <Form.Control type="text" placeholder={tr('"Search for dishes..."')} />
                          <InputGroup.Append>
                            <Button type="button" variant="link">
                              <Icofont icon="search" />
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form> */}
                      {/* <h6 className="mb-3">{tr('Most Popular')}  <Badge variant="success">	<Icofont icon="tags" /> 15% {tr('Off All Items')} </Badge></h6>
                      <ItemsCarousel /> */}

                      {/* <Row>
												<h5 className="mb-4 mt-3 col-md-12">Best Sellers</h5>
												<Col md={4} sm={6} className="mb-4">
													<BestSeller
														id={1}
														title='World Famous'
														subTitle='North Indian • American • Pure veg'
														imageAlt='Product'
														image='img/list/1.png'
														imageClass='img-fluid item-img'
														price={250}
														priceUnit='$'
														isNew={true}
														showPromoted={true}
														promotedVariant='dark'
														favIcoIconColor='text-danger'
														rating='3.1 (300+)'
													//getValue={  }
													/>
												</Col>

												<Col md={4} sm={6} className="mb-4">
													<BestSeller
														id={2}
														title='The osahan Restaurant'
														subTitle='North Indian • American • Pure veg'
														imageAlt='Product'
														image='img/list/6.png'
														imageClass='img-fluid item-img'
														price={250}
														priceUnit='$'
														qty={1}
														showPromoted={true}
														promotedVariant='dark'
														favIcoIconColor='text-danger'
														rating='3.1 (300+)'
													//getValue={  }
													/>
												</Col>

												<Col md={4} sm={6} className="mb-4">
													<BestSeller
														id={3}
														title='Bite Me Sandwiches'
														subTitle='North Indian • American • Pure veg'
														imageAlt='Product'
														image='img/list/3.png'
														imageClass='img-fluid item-img'
														price={250}
														priceUnit='$'
														showPromoted={true}
														promotedVariant='dark'
														favIcoIconColor='text-danger'
														rating='3.1 (300+)'
													//getValue={  }
													/>
												</Col>
											</Row> 
                      
                        if (!hasOrdering) {
        alert(tr("This business cannot take orders at the moment"))
        return
      }
                      
                      */}

                      {(hasOrdering === false || ordersAvailable === false) &&
                        <Row>
                          <Col md={12} >
                            <h5>
                              {tr("This business cannot take orders at the moment, please check back later")}
                            </h5>
                          </Col>
                        </Row>

                      }


                      <Row>
                        <Col md={12} >
                          <h5 onClick={() => { shareData(shareURL) }} style={{ cursor: "pointer" }}>
                            <label style={{ color: "#F1AD3E", cursor: "pointer" }}>Click aqui </label> para compartir este menú  <i className="icofont-share"></i>
                          </h5>
                        </Col>
                      </Row>

                      <div className="row justify-content-center category-scroll-menu"
                        style={_styleForCategoryScrollMenu()}>
                        <div className="horizontal-scrollmenu">
                          {categories.map((category, index) => {
                            return (
                              <div className="category-scroll-name"
                                key={`category${category.id}`}
                                onClick={() => {
                                  setCurrentCategory(category)
                                  document.getElementById(`category_${category.id}`).scrollIntoView();
                                }}
                                style={styleForCategory(category)}
                              >
                                {category.name}
                              </div>
                            )

                          })}
                        </div>
                      </div>



                      {categories.map((data, index) => {

                        return (<Row key={index} id={`category_${data.id}`} >
                          <h5 className="mb-4 mt-3 col-md-12">{data.name} <small className="h6 text-black-50">{data.Products.filter((p) => p.available === true).length} {tr('ITEMS')}</small></h5>
                          <Col md={12} style={{
                            padding: 4
                          }}>
                            <div className="bg-white rounded border shadow-sm mb-4">
                              {data.Products.filter((p) => p.available === true).map((product, i) => {
                                return (<QuickBite
                                  key={i}
                                  product={product}
                                  categories={categories}
                                  id={product.id}
                                  itemClass="menu-list"
                                  image={product.photo}
                                  title={product.name}
                                  price={product.price}
                                  hasOrdering={ordersAvailable}
                                  priceUnit='$'
                                  getValue={(args) => {
                                    //console.log("getValue add to cart", product.id, args);
                                    if (args.quantity === 1) {
                                      // make a copy of the object
                                      let newItem = Object.assign({}, product);
                                      newItem.quantity = 1
                                      newItem.menu_id = product.menu_id || selectedMenu.id
                                      addItemToCart(newItem, args);
                                    } else if (args.quantity === 0) {
                                      removeItem(product, args);
                                    } else {
                                      setItemQuantity(product, args.quantity);
                                    }

                                  }}
                                  detailColour={'#F1AD3E'}
                                  optionsFirst={optionsFirst}
                                />)
                              })}

                            </div>
                          </Col>
                        </Row>)
                      })}

                    </Tab.Pane>

                    {/* {gallery} */}

                    {/* info tab */}

                    { /* book table tab */}


                    { /* ratings tab */}
                  </Tab.Content>
                </div>
              </Col>
              <Col md={4} className="hide-cart "
              // onClick={() => setOpenCart(!openCart)/*pricingSection.current?.scrollIntoView({ behavior: 'smooth' })*/}
              // style={{ cursor: 'pointer' }}
              >
                {openCart
                  ?
                  <div className="generator-bg rounded shadow-sm p-4 osahan-cart-item sticky-position-up "
                    ref={pricingSection}
                    style={{ maxHeight: "410px" }}>
                    <div className='row'
                      onClick={() => setOpenCart(!openCart)}
                      style={{ cursor: 'pointer' }}>
                      <div className='col-7' style={{ paddingTop: "8px" }}>
                        <h5 className="mb-1 text-white">{tr("Cart")}
                        </h5>
                        <span className=" text-white">{cartItems.length} {tr("Items")}</span>
                      </div>
                      <div className='col-5' style={{ color: "white", display: "flex", justifyContent: "center" }}>
                        <div className='row'>
                          <div className='col-12 ' style={{ color: "white", padding: 0, display: "flex", justifyContent: "center" }}>
                            <i className="fa fa-arrow-down fa-lg" aria-hidden="true"></i>
                          </div>
                          <div className='col-12 ' style={{ color: "white", display: "flex", justifyContent: "center" }}>
                            <i className='fa fa-shopping-cart fa-3x'></i>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="bg-white rounded shadow-sm mb-2" style={{ maxHeight: "130px", overflow: "scroll" }}>
                      {cartItems.map((item, index) => {
                        return <CheckoutItem
                          key={'cart_item_' + index}
                          itemName={item.name}
                          cartItem={item}
                          price={item.price}
                          priceUnit="$"
                          id={item.id}
                          quantity={item.quantity}
                          show={true}
                          minValue={0}
                          maxValue={7}
                          setValue={(args) => {
                            //console.log("checkout item", args);
                            if (args.quantity === 0) {
                              removeItem(item, args);
                            } else {
                              setItemQuantity(item, args.quantity);
                            }
                          }}
                        />
                      }
                      )}

                    </div>
                    <div className="mb-2 bg-white rounded p-2 clearfix">
                      <Image fluid className="float-left" src="/img/wallet-icon.png" />
                      <h6 className="font-weight-bold text-right mb-2">{tr('Subtotal')} : <span className="text-danger">{totalItemsCost()}</span></h6>
                      <p className="seven-color mb-1 text-right">{tr("Extra charges may apply")}</p>
                      {/* <p className="text-black mb-0 text-right hidden">You have saved $955 on the bill</p> */}
                    </div>
                    <Link to={checkoutLocation} className="btn btn-success btn-block btn-lg">{tr('Next')}
                      <Icofont icon="long-arrow-right" /></Link>
                    <div className="pt-2"></div>
                    {/*  <div className="alert alert-success hidden" role="alert">
                  You have saved <strong>$1,884</strong> on the bill
                </div> */}
                    <div className="pt-2"></div>
                  </div>
                  :
                  <div className={`${cartItems.length === 0 ? 'generator-bg' : 'cart-color'} rounded shadow-sm  osahan-cart-item`}
                    onClick={() => setOpenCart(!openCart)}
                    style={{ maxHeight: "80px", cursor: 'pointer', padding: "12px", paddingLeft: "24px" }}>
                    <div className='row'>
                      <div className='col-7' style={{ paddingTop: "8px" }}>
                        <h5 className="mb-1 text-white">{tr("Cart")}
                        </h5>
                        <span className=" text-white">{cartItems.length} {tr("Items")}</span>
                      </div>

                      <div className='col-5' style={{ color: "white", display: "flex", justifyContent: "center" }}>
                        <div className='row'>
                          <div className='col-12 ' style={{ color: "white", padding: 0, display: "flex", justifyContent: "center" }}>
                            <i className="fa fa-arrow-up fa-lg" aria-hidden="true"></i>
                          </div>
                          <div className='col-12 ' style={{ color: "white", display: "flex", justifyContent: "center" }}>
                            <i className='fa fa-shopping-cart fa-3x'></i>
                          </div>
                        </div>

                      </div>

                      {/* <div className='col-4 ' style={{ color: "white",display:"flex", justifyContent:"right" }}>
                        <i className='fa fa-shopping-cart fa-3x'></i>
                      </div>
                      <div className='col-2 ' style={{ color: "white", padding:0}}>
                        <i className="fa fa-arrow-up fa-2x" aria-hidden="true"></i>
                      </div> */}
                    </div>
                  </div>
                }

              </Col>
              <Col md={4} className="">

                {_getOffer()}

                <div className="show-text generator-bg rounded shadow-sm mb-4 p-4 osahan-cart-item sticky-position-up " ref={pricingSection}>
                  <div className='' >
                    <h5 className="mb-1 text-white">{tr("Your Order")}
                    </h5>
                    <p className="mb-4 text-white">{cartItems.length} {tr("Items")}</p>
                  </div>
                  <div className="bg-white rounded shadow-sm mb-2" >
                    {cartItems.map((item, index) => {
                      //console.log("checkout item", item)
                      return <CheckoutItem
                        key={'cart_item_' + index}
                        itemName={item.name}
                        cartItem={item}
                        price={item.chosenPrice || item.price}
                        priceUnit="$"
                        id={item.id}
                        quantity={item.quantity}
                        show={true}
                        minValue={0}
                        maxValue={7}
                        setValue={(args) => {
                          //console.log("checkout item", args);
                          if (args.quantity === 0) {
                            removeItem(item, args);
                          } else {
                            setItemQuantity(item, args.quantity);
                          }
                        }}
                      />
                    }
                    )}

                  </div>
                  <div className="mb-2 bg-white rounded p-2 clearfix">
                    <Image fluid className="float-left" src="/img/wallet-icon.png" />
                    <h6 className="font-weight-bold text-right mb-2">{tr('Subtotal')} : <span className="text-danger">{totalItemsCost()}</span></h6>
                    <p className="seven-color mb-1 text-right">{tr("Extra charges may apply")}</p>
                    {/* <p className="text-black mb-0 text-right hidden">You have saved $955 on the bill</p> */}
                  </div>
                  <Link to={checkoutLocation} className="btn btn-success btn-block btn-lg">{tr('Next')}
                    <Icofont icon="long-arrow-right" /></Link>
                  <div className="pt-2"></div>
                  {/*  <div className="alert alert-success hidden" role="alert">
                    You have saved <strong>$1,884</strong> on the bill
                  </div> */}
                  <div className="pt-2"></div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

      </Tab.Container>
    </>
  );

}


export default Detail;
/* eslint-disable no-unused-vars */
function oldTabs(props) {

  //const { tr /* , getTranslations, locale, translations, setLocale */ } = useTranslations();

  /* eslint-disable no-unused-vars */
  function _infoTab(menuData) {
    /*  return (
       <Tab.Pane eventKey="third">
         <div id="restaurant-info" className="bg-white rounded shadow-sm p-4 mb-4">
           <div className="address-map float-right ml-5">
             <div className="mapouter">
               <div className="gmap_canvas">
                 <iframe title='addressMap' width="300" height="170" id="gmap_canvas" src="https://maps.google.com/maps?q=university%20of%20san%20francisco&t=&z=9&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe></div>
             </div>
           </div>
           <h5 className="mb-4">{tr('Restaurant Info')}</h5>
           <p className="mb-3">{menuData.Location.address}
             <br /> {menuData.Location.city}-{menuData.Location.province}
           </p>
           <p className="mb-2 text-black"><Icofont icon="phone-circle text-primary mr-2" /> {menuData.Business.phone}</p>
           <p className="mb-2 text-black"><Icofont icon="email text-primary mr-2" /> {menuData.Business.email}</p>
           <p className="mb-2 text-black"><Icofont icon="clock-time text-primary mr-2" /> Today  11am – 5pm, 6pm – 11pm
             <Badge variant="success" className='ml-1'> OPEN NOW </Badge> 
           </p>
           <hr className="clearfix" />
           <p className="text-black mb-0">You can also check the 3D view by using our menue map clicking here &nbsp;&nbsp;&nbsp; <Link className="text-info font-weight-bold" to="#">Venue Map</Link></p>
           <hr className="clearfix" />
           <h5 className="mt-4 mb-4">Más Información</h5>
           <div className="border-btn-main mb-4">
             {menuData.Category.Categories.map((data, index) => {
               return (
                 <Link key={index} className="border-btn text-success mr-2" to="#"><Icofont icon="check-circled" /> {data.name}</Link>
               )
             })}
           </div>
         </div>
       </Tab.Pane>
     ) */
  }

  /* eslint-disable no-unused-vars */
  function _galleryTab() {
    /* return (
      <Tab.Pane eventKey="second">
        <div className='position-relative'>
          <GalleryCarousel />
        </div>
      </Tab.Pane>
    ) */
  }

  /* eslint-disable no-unused-vars */
  function _bookTableTab() {
    /* return (
      <Tab.Pane eventKey="fourth">
        <div id="book-a-table" className="bg-white rounded shadow-sm p-4 mb-5 rating-review-select-page">
          <h5 className="mb-4">Book A Table</h5>
          <Form>
            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter Full Name" />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control type="text" placeholder="Enter Email address" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Mobile number</Form.Label>
                  <Form.Control type="text" placeholder="Enter Mobile number" />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Date And Time</Form.Label>
                  <Form.Control type="text" placeholder="Enter Date And Time" />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="text-right">
              <Button variant="primary" type="button"> Submit </Button>
            </Form.Group>
          </Form>
        </div>
      </Tab.Pane>
    ) */
  }

  /* eslint-disable no-unused-vars */
  function _ratingsTab() {
    return (
      <Tab.Pane eventKey="fifth">
        <div id="ratings-and-reviews" className="bg-white rounded shadow-sm p-4 mb-4 clearfix restaurant-detailed-star-rating">
          <div className="star-rating float-right">
            <StarRating fontSize={26} star={5} getValue={(args) => {
              console.log("star rating", args);
            }}

            />
          </div>
          <h5 className="mb-0 pt-1">Rate this Place</h5>
        </div>
        <div className="bg-white rounded shadow-sm p-4 mb-4 clearfix graph-star-rating">
          <h5 className="mb-0 mb-4">Ratings and Reviews</h5>
          <div className="graph-star-rating-header">
            <div className="star-rating">
              <StarRating fontSize={18} disabled={true} star={5}
                getValue={(args) => {
                  console.log("star rating", args);
                }}
              />
              <b className="text-black ml-2">334</b>
            </div>
            <p className="text-black mb-4 mt-2">Rated 3.5 out of 5</p>
          </div>
          <div className="graph-star-rating-body">
            <RatingBar leftText="5 Star" barValue={56} />
            <RatingBar leftText="4 Star" barValue={23} />
            <RatingBar leftText="3 Star" barValue={11} />
            <RatingBar leftText="2 Star" barValue={6} />
            <RatingBar leftText="1 Star" barValue={4} />
          </div>
          <div className="graph-star-rating-footer text-center mt-3 mb-3">
            <Button type="button" variant="outline-primary" size="sm">Rate and Review</Button>
          </div>
        </div>
        <div className="bg-white rounded shadow-sm p-4 mb-4 restaurant-detailed-ratings-and-reviews">
          <Link to="#" className="btn btn-outline-primary btn-sm float-right">Top Rated</Link>
          <h5 className="mb-1">All Ratings and Reviews</h5>
          {/* <Review
          image="/img/user/1.png"
          ImageAlt=""
          ratingStars={5}
          Name='Singh Osahan'
          profileLink="#"
          reviewDate="Tue, 20 Mar 2020"
          reviewText="Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classNameical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classNameical literature, discovered the undoubtable source. Lorem Ipsum comes from sections"
          likes="856M"
          dislikes="158K"
          otherUsers={}
        />
        <hr />
        <Review
          image="/img/user/6.png"
          ImageAlt=""
          ratingStars={5}
          Name='Gurdeep Osahan'
          profileLink="#"
          reviewDate="Tue, 20 Mar 2020"
          reviewText="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
          likes="88K"
          dislikes="1K"
          otherUsers={}
        /> */}
          <hr />
          <Link className="text-center w-100 d-block mt-4 font-weight-bold" to="#">See All Reviews</Link>
        </div>
        <div className="bg-white rounded shadow-sm p-4 mb-5 rating-review-select-page">
          <h5 className="mb-4">Leave Comment</h5>
          <p className="mb-2">Rate the Place</p>
          <div className="mb-4">
            <div className="star-rating">
              <StarRating fontSize={26} star={5}
                getValue={(args) => {
                  console.log("star rating", args);
                }}
              />
            </div>
          </div>
          <Form>
            <Form.Group>
              <Form.Label>Your Comment</Form.Label>
              <Form.Control as="textarea" />
            </Form.Group>
            <Form.Group>
              <Button variant="primary" size="sm" type="button"> Submit Comment </Button>
            </Form.Group>
          </Form>
        </div>
      </Tab.Pane>
    )
  }
}