
import React, { useState  /*, useContext, useEffect, , useEffect */ } from 'react';
import { Form, InputGroup, Modal, ButtonToolbar, Button, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
//import flag from '../../img/colombia_icon.png'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
//import Icofont from 'react-icofont';
//import { values } from 'lodash';

import useTranslations from "../../lib/useTranslations";

function AddAddressModal(props) {
  const [city, setCity] = useState(null)
  const [address, setAddress] = useState(null)
  const [name, setName] = useState(null)
  const [phone, setPhone] = useState(null)
  const [email, setEmail] = useState("")
  const [instructions, setInstructions] = useState(null)

  const { tr /* , getTranslations, locale, translations, setLocale */ } = useTranslations();

  const [nickname, setNickname] = useState(tr('Home'))

  const doSubmit = () => {
    if (name === null || name.length < 3) {
      alert(tr("Please enter a contact name"))
      return
    }

    if (city === null || city.length < 3) {
      alert(tr("Please enter a valid city"))
      return
    }

    if (address === null || address.length < 5) {
      alert(tr("Please enter a valid address"))
      return
    }
    if (phone === null || phone.length < 12) {
      alert(tr("Please enter a valid phone number"))
      return
    }

    let addressObj = {
      name: name,
      city: city,
      address: address,
      phone: `+${phone}`,
      email: email.trim(),
      title: nickname,
      instructions: instructions
    }

    console.log("doSubmit", addressObj)

    props.onSubmit(addressObj)
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      centered
    >
      <Modal.Header closeButton={true}>
        <Modal.Title as='h5' id="add-address">{tr("Add Delivery Address")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <div className="form-row">

            <Form.Group className="col-md-12">
              <Form.Label>{tr("Name")}</Form.Label>
              <Form.Control type="text" placeholder={tr("Contact name")}
                onChange={(event) => {
                  // console.log("phone", event.target.value)
                  setName(event.target.value)
                }} />
            </Form.Group>

            <Form.Group className="col-md-12">
              <Form.Label>{tr("City")}</Form.Label>
              <InputGroup>
                <Form.Control type="text" placeholder={tr("City")}
                  onChange={(event) => {
                    // console.log("city", event.target.value)
                    setCity(event.target.value)
                  }}
                />
                {/* 
                <InputGroup.Append>
                   <Button variant="outline-secondary" type="button" id="button-addon2"><Icofont icon="ui-pointer"/></Button>
                </InputGroup.Append>
              */}
              </InputGroup>
            </Form.Group>

            <Form.Group className="col-md-12">
              <Form.Label>{tr("Complete Address")}</Form.Label>
              <Form.Control type="text" placeholder={tr("Complete Address e.g. house number, street name, landmark")}
                onChange={(event) => {
                  // console.log("address", event.target.value)
                  setAddress(event.target.value)
                }}
              />
            </Form.Group>

            <Form.Group className="col-md-12">
              <Form.Label>{tr("Phone")} {tr("including country code")}</Form.Label>
              <PhoneInput
                country={'co'}
                onChange={(event) => {
                  //console.log("phone", event)
                  setPhone(event)
                }}
                inputStyle={{width:"100%"}}
              />
              {/* <div className='row' style={{paddingRight:"15px"}}>
                <Form.Label className='col-2 centrar' style={{padding:0, margin:0, display:"flex", alignItems:"center", justifyContent:"center"}}><img src={flag} alt='icon-flag'/></Form.Label>
                <Form.Control className='col-10' type="text" placeholder={tr("Contact phone number")}
                  onChange={(event) => {
                    // console.log("phone", event.target.value)
                    setPhone(event.target.value)
                  }} />
              </div> */}
            </Form.Group>

            <Form.Group className="col-md-12">
              <Form.Label>{tr("Email")}</Form.Label>
              <Form.Control type="text" placeholder={tr("Email for confirmation and receipt")}
                onChange={(event) => {
                  // console.log("phone", event.target.value)
                  setEmail(event.target.value)
                }} />
            </Form.Group>

            <Form.Group className="col-md-12">
              <Form.Label>{tr("Delivery Instructions")}</Form.Label>
              <Form.Control type="text" placeholder={tr("Delivery Instructions e.g. Ring top bell")}
                onChange={(event) => {
                  // console.log("instructions", event.target.value)
                  setInstructions(event.target.value)
                }}
              />
            </Form.Group>

            <Form.Group className="mb-0 col-md-12">
              <Form.Label>{tr("Nickname")}</Form.Label>
              <ButtonToolbar>
                <ToggleButtonGroup className="d-flex w-100" type="radio" name="options" defaultValue={1}
                  onChange={(val) => {
                    console.log("nickname", val)
                    switch (val) {
                      case 1:
                        setNickname(tr("Home"))
                        break;

                      case 2:
                        setNickname(tr("Work"))
                        break;

                      case 3:
                        setNickname(tr("Other"))
                        break;
                      default:
                        setNickname(tr("Unknown"))
                    }
                  }}
                >
                  <ToggleButton variant='info' value={1}>
                    {tr("Home")}
                  </ToggleButton>
                  <ToggleButton variant='info' value={2}>
                    {tr("Work")}
                  </ToggleButton>
                  <ToggleButton variant='info' value={3}>
                    {tr("Other")}
                  </ToggleButton>
                </ToggleButtonGroup>
              </ButtonToolbar>
            </Form.Group>
          </div>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button type='button' onClick={props.onHide} variant="outline-primary" className="d-flex w-50 text-center justify-content-center">{tr("CANCEL")}</Button>
        <Button type='button' variant="primary" className='d-flex w-50 text-center justify-content-center'
          onClick={doSubmit}
        >{tr("SUBMIT")}</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default AddAddressModal;