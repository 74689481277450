import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { Badge } from 'react-bootstrap';
import { getFromApi } from '../lib/api';
import { BusinessContext } from '../lib/BusinessContext';
import LatLon from '../lib/latlon';
import { CartContext } from '../lib/CartContext';
import useTranslations from "../lib/useTranslations";

import TopSearch from './home/TopSearch';
import Icofont from 'react-icofont';


const BusinessCard = (props) => {
  const { photo, name, description } = props.business;
  const { onSelected } = props

  const { business } = props;

  //const [canOrder, setCanOrder] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [openIn, setOpenIn] = useState(null)
  const [closeIn, setCloseIn] = useState(null)

  const { checkMenuPeriods, currentLocation, } = useContext(BusinessContext);

  const { tr } = useTranslations();
  /* 
    const bestDeliveryMenu = (business, menus) => {
  
      let matching = menus
  
      //console.log("matching menus", business.id, business.name, matching, menus)
  
      // if more than 1 menu matches find the closest based on Geo position
      if (matching.length > 1 && currentLocation) {
        let here = new LatLon(currentLocation.latitude, currentLocation.longitude)
        // determine the distance to the menu from the current GEO position
        for (let i = 0; i < matching.length; i++) {
          let menu = matching[i]
          let latlon2 = LatLon.fromString(menu.Location.latlng)
          menu.distance = Math.round(here.distanceTo(latlon2))
        }
        matching.sort((a, b) => { return a.distance - b.distance })
      }
  
      // use the first matching menu
      return matching[0] || menus[0]
    }
  
    const hasOpenMenu = (biz) => {
      let menus = biz.menus
      let result = false
      menus.forEach((m) => {
        let res = checkMenuPeriods(m)
        result = result || res.isOpen
      })
  
      //console.log("hasOpenMenu", biz.name, result, menus)
      return result
    }
  
    const getOpenMenus = (menus) => {
      return menus.filter((m) => {
        let res = checkMenuPeriods(m)
        return res.isOpen
      })
    } */

  //const { tr } = useTranslations();
  useEffect(() => {

    setIsOpen(business.isOpen)

    // check this business can order
    /*   if (business.menus && business.menus.length > 0) {
        let menus = getOpenMenus(business.menus)
        let menu = bestDeliveryMenu(business, menus)
        let res = checkMenuPeriods(menu)
  
        if (res.isOpen) {
          setIsOpen(true)
          if (res.closeIn && res.closeIn > 0 && res.closeIn < 3600) {
            setCloseIn(`${Math.floor(res.closeIn / 60)} min`)
          }
        } else {
          if (res.openIn && res.openIn > 0 && res.openIn < 7200) {
            setOpenIn(`${Math.floor(res.openIn / 60)} min`)
          }
  
          console.log("checkPeriod res", business, res.isOpen ? "OPEN" : "CLOSED",res )
          console.log("menu periods", selectedCity,  menu)
        }
      } */
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [business])

  const timeInfo = () => {
    if (openIn) {
      return (
        <p className="card-text" style={{ color: 'green' }}>
          <span className="bg-light  rounded-sm pl-2 pt-1 pr-2">
            <Icofont icon='wall-clock' /> {tr("Opens in")} {openIn}
          </span>
        </p>
      )

    } else if (closeIn) {
      return (
        <p className="card-text" style={{ color: 'red' }}>
          <span className="bg-light rounded-sm pl-2 pt-1 pr-2">
            <Icofont icon='wall-clock' /> {tr("Closes in")} {closeIn}
          </span>
        </p>
      )
    } else {
      return (
        <p className="card-text">{description}</p>
      )
    }
  }

  //console.log("businnes", name, canOrder, isOpen)

  if (isOpen) {
    return (
      <div className="card card-business online"
        onClick={() => {
          //console.log("clicked", name)
          onSelected()
        }}
      >
        <img className="card-img-top" src={photo} alt="Card image cap" />
        <div className="card-body">
          <h1 className="card-name"> {name} </h1>
          {timeInfo()}
        </div>
      </div>
    );
  }

  return (
    <div className="card card-business offline"
      style={{
        opacity: "0.5"
      }}
    >
      <div className="member-plan position-absolute">
        <Badge pill variant='danger' >Offline</Badge>
      </div>
      <img className="card-img-top" src={photo} alt="Card image cap" />
      <div className="card-body">
        <h1 className="card-name"> {name} </h1>
        {timeInfo()}
      </div>
    </div>
  );
};

const CityPage = (props) => {
  /* eslint-disable no-unused-vars */
  //const [currentLocation, setCurrentLocation] = useState({});
  const [loading, setLoading] = useState(false)

  // const [chosenProvince, setChosenProvince] = useState(null)
  // const [chosenCity, setChosenCity] = useState(null)

  const { businesses, setBusinesses, clearSavedData, checkMenuPeriods, departments, cities, selectedBusiness, selectedMenu, reloadMenu,
    setSelectedBusiness, selectedProvince, setSelectedProvince, provinceSelected,
    selectedCity,
    setSelectedCity,
    setCityName, } = useContext(BusinessContext);

  const { clearCartData } = useContext(CartContext);

  const { tr, getTranslations, locale, translations, setLocale } = useTranslations();

  const history = useHistory();
  const location = useLocation()

  const clearAllData = () => {
    clearSavedData()
    clearCartData()
  }

  const downloadAndNavigateToMenu = (menuId) => {

    localStorage.removeItem("selectedMenu")

    getFromApi(`/api/v1/menu/with_business_and_location/${menuId}`, {}, (data) => {
      //console.log("menu result", menuId, data.result)

      let menu = data.result

      // set the department and city
      setSelectedProvince(menu.Location.province)
      setSelectedCity(menu.Location.city)
      localStorage.setItem('selectedMenu', JSON.stringify(menu))

      //console.log("menu data", menu)
      const location = {
        pathname: '/detail',
        state: { selectedMenu: menu }
      }

      history.push(location)
    }, (error) => {
      console.log('get menu failed', error)
    })

  }

  const hasOpenMenu = (biz) => {
    let menus = biz.Menus
    let result = false
    menus.forEach((m) => {
      let res = checkMenuPeriods(m)
      result = result || res.isOpen
    })

    //console.log("hasOpenMenu", biz.name, result, menus)
    return result
  }

  useEffect(() => {

    let city = selectedCity
    let province = selectedProvince
    let country = 'Colombia'

    if (location.state) {
      city = location.state.city
      province = location.state.province

      // reset selected city and provicne on reload
      if (city && !selectedCity) {
        setSelectedCity(city)
      }

      if (province && !selectedProvince) {
        setSelectedProvince(province)
      }
    }

    //console.log("useEffect with", city, province, location.state)

    // get the menus for the selected city - each menu has the business, location and periods

    let businessList = [];

    let url = `/api/v1/menu/city_menus/${city}/${province}/${country}`
    //console.log("getBusinessesForCity from", url)

    getFromApi(url, {}, (data) => {
      //console.log("GetMenusForCity result", city, data.result)

      businessList = data.result.filter((b) => b.Menus.length > 0)

      //let here = new LatLon(currentLocation.latitude, currentLocation.longitude);

      // determine the subscription level of each business
      businessList.forEach(biz => {
        // filter out disabled menus or not in the same city 
        biz.Menus = biz.Menus.filter((m) => {
          //console.log("filter",biz, m.Location, m)
          return m.enabled && m.Location && m.Location.city == city
        })

        biz.isOpen = hasOpenMenu(biz)

        if (biz.Menus.length > 0) {
          biz.rank = biz.Menus.map((m) => m.Location.rank).reduce((accumulator, currentValue) => Math.min(accumulator, currentValue))
          //console.log("use rank", biz.name, biz.rank)
        }
      })

      // seperate into online and offline lists
      let online = businessList.filter((b) => b.isOpen)
      let offline = businessList.filter((b) => !b.isOpen)

      // sort the restaurants by rank
      online.sort((a, b) => {
        return a.rank - b.rank
      })
      offline.sort((a, b) => {
        return a.rank - b.rank
      })

      businessList = [...online, ...offline]

      //console.log("getBusinessesForCity businesses", businessList)
      setBusinesses(businessList)
      //ensureOnlineBusinesses(location, businessList)
    }, (error) => {
      console.log('failed', error)
    })


  }, [])

  const _getBusinessGrid = (businesses) => {
    const breakpointColumnsObj = {
      default: 5,
      1200: 4,
      900: 3,
      700: 2,
      500: 1
    };

    if (loading) {
      return (
        <>
          <div className='row justify-content-center' style={{ margin: 0 }}>
            <div className='loader '></div>

          </div>
          <div className='row justify-content-center' style={{ margin: 0 }}>
            <div>
              Cargando...
            </div>
          </div>
        </>
      )
    }

    else {
      return (
        <div
          className="businesses"
        >
          {businesses.map((business, index) => (
            <BusinessCard business={business} key={`hp_biz_` + index} onSelected={() => {
              //console.log("selected business", business)
              //setSelectedBusiness(business)

              if (business.Menus.length > 1) {
                const location = {
                  pathname: `/b/${business.name}/${selectedCity}/show`,
                  state: {
                    business: business,
                    city: selectedCity, province: selectedProvince
                  }
                }

                history.push(location)
              } else {
                // navigate to the menu
                downloadAndNavigateToMenu(business.Menus[0].id)
              }

            }} />
          ))}
        </div>
      )
    }
  }

  /*  if (selectedBusiness || selectedMenu) {
     console.log("selected business", selectedCity, selectedProvince, selectedBusiness, selectedMenu)
 
     return (
       <BusinessPage business={selectedBusiness} />
     )
   }
  */
  if (businesses && businesses.length > 0) {
    //console.log("businesses", businesses)
    return (
      <>
        <TopSearch />

        <section className="section pt-3 pb-5 bg-white homepage-add-section ">
          {_getBusinessGrid(businesses)}
        </section>
      </>
    )
  }

  return (
    <div className="home-body">

      <div className="home-body-content mt-5 pl-4">
        {selectedCity && businesses && businesses.length == 0 &&
          <div className="row mb-4">
            <div className="col-12">
              <span className="fs-1-5 fw-400 poppins">No hay ningún negocio abierto en la ciudad elegida.</span>

            </div>
          </div>

        }
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-12">
                <h1>Pide de tu Restaurante Favorito al Mejor Precio.</h1>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12">
                <h3>Sin registro ni descarga.</h3>

              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12">
                <span className="fs-1-5 fw-400 poppins">Conoce aliados cerca de ti.</span>

              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12">
                <select className="province-selector"

                  onChange={(event) => {
                    clearAllData()
                    setSelectedCity('')
                    provinceSelected(event.target.value);

                    const location = {
                      pathname: `/p/${event.target.value}/show`,
                      state: { province: event.target.value }
                    }

                    history.push(location)
                  }}
                >
                  <option value="-">Elije tu departamento</option>

                  {departments.map((dept, index) => {
                    if (selectedProvince == dept) {
                      return (
                        (
                          <option key={'dept_' + index} value={dept} selected>{dept}</option>
                        )
                      )
                    } else {
                      return ((
                        <option key={'dept_' + index} value={dept}>{dept}</option>
                      ))
                    }

                  })}
                </select>

              </div>
            </div>

            {selectedProvince && cities &&
              <div className="row mt-4">
                <div className="col-12">
                  <select className="province-selector"
                    onChange={(event) => {
                      let city = event.target.value
                      if (city == '-') city = ''
                      clearAllData()
                      setSelectedCity(city)
                    }}
                  >
                    {selectedCity == '' &&
                      <option value="-" selected>Elije tu ciudad</option>
                    }

                    {cities.map((city, index) => (
                      <option key={'dept_' + index} value={city}>{city}</option>
                    ))}
                  </select>

                </div>
              </div>

            }

            <div className="row mt-4">
              <div className="col-12">
                <h3>¡Pide Yá!</h3>

              </div>
            </div>

          </div>
          <div className="col">
            <img src="/images/burger.png"></img>

          </div>
        </div>

      </div>

      <div className="pb-2 mt-2 text-center ">
        <div>
          <p className="mt-0 mb-0">© Copyright 2022 ZONO VENTURES SAS. All Rights Reserved</p>
        </div>
      </div>

    </div>
  );
}

export default CityPage