import React, { useState, useRef, useEffect, useContext /* */ } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icofont from 'react-icofont';
import CartDropdownItem from './cart/CartDropdownItem';


import { getFromApi } from '../lib/api';
import LatLon from '../lib/latlon';
import useTranslations from "../lib/useTranslations";
import { BusinessContext } from '../lib/BusinessContext';

//import useTranslations from "../lib/useTranslations";
function TrackOrder(props) {

  let d = new Date()
  const [timer, setTimer] = useState(null)
  const [confirmed, setConfirmed] = useState(null)
  const [deliveryChargeRange, setChargeRange] = useState(null)
  const [deliverType, setDeliverType] = useState(null)
  const [readyIn, setReadyIn] = useState(30)
  const [order, setOrder] = useState({
    Business: {},
    Location: { latlng: "7.117969, -73.109473" },
    deliveryData: {},
    items: [],
    tstamp: d.toISOString()
  })

  const { locationConfigValue } = useContext(BusinessContext);

  const timerRef = React.useRef(null)
  timerRef.current = timer

  const orderRef = React.useRef(null)
  orderRef.current = order

  const confRef = useRef(null)
  confRef.current = confirmed

  const { tr /* , getTranslations, locale, translations, setLocale */ } = useTranslations();

  function _formatDate(timestamp, datetime) {
    const options = { hour: '2-digit', minute: '2-digit' }
    const df = new Intl.DateTimeFormat('es-CO', options)

    let dt = timestamp != null ? new Date(timestamp) : Date.parse(datetime)

    return df.format(dt)
  }

  const _checkOrderUpdates = () => {
    //console.log('_checkOrderUpdates', orderRef.current)

    getFromApi("/api/v1/order/" + orderRef.current.id, {}, (response) => {
      let order = orderRef.current
      let orderObj = response.result

      //console.log('_checkOrderUpdates order', response.result, order)

      if (orderObj.status !== order.status /* && order.deliverType === "delivery" */) {
        // the order has changed
        console.log("order changed", order.status, " --> ", orderObj.status)

        // merge the saved data
        orderObj.Business = order.Business
        orderObj.Location = order.Location
        orderObj.deliveryData = order.deliveryData
        orderObj.menuId = order.menuId  // ensure the order has the menu ID so we can detect when the menu changes
        // save the changed order to local storage
        //localStorage.setItem('order', JSON.stringify(response.result))

        setOrder(orderObj)

        if (orderObj.status === "preparing") {
          // confirmed 
          setConfirmed(new Date())
        }
      } else if (orderObj.status === "preparing" && !confRef.current) {
        // we missed the update to preparing
        console.log("set confirmed to now")
        setConfirmed(new Date())
      }

      if (confRef.current) {
        let d = Date.now()
        let then = confRef.current.getTime()

        // default 30 mins preperation time after confirmation
        let minsDiff = 30 - (((d - then) / 1000) / 60)

        if (minsDiff > 0) {
          setReadyIn(Math.floor(minsDiff))
        }
      }
    },
      (error) => {

      })

  }

  const orderStatusComponent = (order, stage) => {
    let next = <span>{tr("NEXT")}</span>
    let done = <Icofont icon="check-circled" className="icofont-2x text-success" />

    if (stage === "confirmed") {
      if (order.status === "new") {
        return next
      } else {
        return done
      }
    } else if (stage === "ready") {
      return (<span>{tr("LATER")} (ET : {readyIn}min)</span>)

    } else if (stage === "dispatched") {
      if (order.status === "ready") {
        return next
      } else if (order.status === "dispatched") {
        return done
      }

      // subtract the confirmed time from the current time
      return (<span>{tr("LATER")} (ET : {readyIn}min)</span>)
    }

    return <span></span>
  }

  useEffect(() => {
    let po = localStorage.getItem('pendingOrder')

    //console.log("pending order", po)

    if (po) {
      let orderObj = JSON.parse(po)

      // check this is a pending order from Thanks page, not history
      if (!orderObj.deliveryData) {
        orderObj.deliveryData = {
          name: orderObj.name,
          email: orderObj.email,
          phone: orderObj.phone
        }
        if (orderObj.deliverType === "delivery") {
          orderObj.deliveryData.address = orderObj.destination
        }
      }

      setOrder(orderObj)
      setChargeRange(locationConfigValue(orderObj.Business.Configs, orderObj.Location.id, "deliveryChargeRange", null))
      setDeliverType(orderObj.deliverType)

      //console.log("pending order", orderObj)
    } else {
      // load the last order
      let hdata = localStorage.getItem('orderHistory');
      let addrData = localStorage.getItem('addressList');
      let hd = []
      let max = 0
      let addrList

      if (hdata) {
        hd = JSON.parse(hdata);
      }

      if (hd.length > 0) {
        hd.forEach((o) => max = Math.max(max, o.id))

        // load the order
        getFromApi(`/api/v1/order/${max}/with_business_and_location`, {}, (response) => {
          let orderObj = response.result

          orderObj.deliveryData = {
            name: orderObj.name,
            email: orderObj.email,
            phone: orderObj.phone
          }
          if (orderObj.deliverType === "delivery") {
            orderObj.deliveryData.address = orderObj.destination
            orderObj.deliveryData.title = 'Casa'

            // determine the address title
            if (addrData) {
              addrList = JSON.parse(addrData)
              addrList.forEach((a) => {
                let parts = orderObj.destination.split(",")
                if (a.address.includes(parts[0])) {
                  orderObj.deliveryData.title = a.title
                }
              })
            }
          }

          setOrder(orderObj)
          setDeliverType(orderObj.deliverType)
          setChargeRange(locationConfigValue(orderObj.Business.Configs, orderObj.Location.id, "deliveryChargeRange", null))

        },
          (error) => {

          })

      } else {
        console.log("no order history")
        window.location.href = "/"
      }

      //console.log("no order")
    }

    let timer = setInterval(() => {
      // check for updates from the API server
      _checkOrderUpdates()
    }, 15000)

    setTimer(timer)


    return () => {
      if (timerRef.current !== null) {
        console.log("clear interval")
        clearInterval(timerRef.current)
        setTimer(null)
      } else {
        console.log("no interval to clear")
      }

    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [])

  let latlon = LatLon.fromString(order.Location.latlng)

  let frameUrl = `https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d13696.650704896498!2d${latlon.lon}!3d${latlon.lat}!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1555184720550!5m2!1sen!2sin`

  console.log("render with", order, latlon)

  return (
    <section className="section bg-white osahan-track-order-page position-relative">
      <iframe title="Address" className="position-absolute"
        src={frameUrl}
        width="100%" height="676" frameBorder="0" style={{ border: 0 }} allowFullScreen=""></iframe>
      <Container className="pt-5 pb-5">
        <Row className="d-flex align-items-center">
          <Col md={6} className="text-center pb-4">
            <div className="osahan-point mx-auto"></div>
          </Col>
          <Col md={6}>
            <div className="bg-white p-4 shadow-lg mb-2 track-order-header">
              <div className="mb-2 track-order-number"><small>{tr("Order")} #{order.orderNumber}
                {/*  <Link className="float-right font-weight-bold" to="#"><Icofont icon="headphone-alt" /> HELP</Link> */}

              </small></div>
              <h6 className="mb-1 mt-1 track-business-name">{/* <Link to="/detail" className="text-black">{order.Business.name}
                </Link> */}
                {order.Business.name}
              </h6>
              <p className="text-gray mb-0 track-order-summary"><Icofont icon="clock-time" /> {_formatDate(null, order.tstamp)} | {order.items.length} {tr("Items")} | ${order.totalcost}</p>
            </div>

            <div className="bg-white p-4 shadow-lg">
              <div className="osahan-track-order-detail po">
                <h5 className="mt-0 mb-3">{tr("Order Details")}</h5>
                <Row>
                  <Col md={5}>
                    <small>{tr("FROM")}</small>
                    <h6 className="mb-1 mt-1"><Icofont icon="food-cart" /> {order.Business.name}
                    </h6>
                    <p className="text-gray mb-5">{order.Location.address}, {order.Location.city}</p>
                    <small>{tr("DELIVER TO")}</small>
                    <h6 className="mb-1 mt-1"><span className="text-black"><Icofont icon="map-pins" /> {order.deliveryData.title}
                    </span>
                    </h6>
                    <p className="text-gray mb-0 track-order-address">{order.deliveryData.address}, {order.deliveryData.city}
                    </p>
                  </Col>
                  <Col md={7}>
                    <div className="mb-2"><small><Icofont icon="list" /> {order.items.length} {tr("Items")}</small></div>

                    {order.items.map((item, index) => {
                      return (
                        <CartDropdownItem
                          key={'item ' + index}
                          icoIcon='ui-press'
                          iconClass='text-danger food-item'
                          title={item.name}
                          price={'$' + item.price}
                          item={item}
                        />
                      )
                    })}

                    <hr />
                    <p className="mb-0 font-weight-bold text-black">{tr("TOTAL BILL")}  <span className="float-right text-secondary">${order.totalcost}</span></p>
                    {/*  <p className="mb-0 text-info"><small>Paid via Credit/Debit card
                      <span className="float-right text-danger">$620 OFF</span></small>
                    </p> */}
                    {deliveryChargeRange && deliverType === "delivery" &&
                      <p className="mb-1">{tr("Estimated Delivery Fee")}
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-top">
                              {tr("Actual cost varies")}
                            </Tooltip>
                          }
                        >
                          <span className="text-info ml-1">
                            <Icofont icon="info-circle" />
                          </span>
                        </OverlayTrigger>
                        <span className="float-right text-dark">{deliveryChargeRange}</span>
                      </p>
                    }
                  </Col>
                </Row>
              </div>
            </div>
            <div className="bg-white p-4 shadow-lg mt-2">
              <Row className="text-center">
                <Col>
                  <Icofont icon="tasks" className="icofont-3x text-info" />
                  <p className="mt-1 font-weight-bold text-dark mb-0">{tr("Order Received")}</p>
                  <small className="text-info mb-0"><Icofont icon="check-circled" className="icofont-2x text-success" /></small>
                </Col>
                <Col>
                  <Icofont icon="check-circled" className="icofont-3x text-success" />
                  <p className="mt-1 font-weight-bold text-dark mb-0">{tr("Order Confirmed")}</p>
                  <small className="text-success mb-0">{orderStatusComponent(order, "confirmed")}</small>
                </Col>
                <Col>
                  <Icofont icon="delivery-time" className="icofont-3x text-primary" />
                  <p className="mt-1 font-weight-bold text-dark mb-0">{tr("Order Picked Up")}</p>
                  <small className="text-primary mb-0">
                    {orderStatusComponent(order, "dispatched")}
                  </small>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );

}


export default TrackOrder;